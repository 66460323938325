import React from 'react';
import { useEffect, useState, useRef } from "react";
import Encode from "jwt-encode";
import Lottie from "lottie-react";
import animationData from "../../asset/Animation - 1712919790429.json";

const SECRET = process.env.REACT_APP_SECRET;
const SITE_REFERENCE = process.env.REACT_APP_SITE_REFERENCE;
const BASEURL_USER = process.env.REACT_APP_BASEURL_USER;
const BASEURL_ORDER = process.env.REACT_APP_BASEURL_ORDER;
const BASEURL_PAYMENT = process.env.REACT_APP_BASEURL_PAYMENT;
const TRUST_JS_SECRET = process.env.REACT_APP_TRUST_JS_SECRET;

const TrustPageComponent = () => {
  const [loading, setLoading] = useState(true);
  const [failure, setFailure] = useState(false)
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const url = window.location.href;
    const splitUrl = url.split("?");
    const orderPayload = splitUrl[1];

    const order = orderPayload.split("=");
    const orderId = order[1];

    async function authTokenGenerator() {

      const clientUrl = BASEURL_USER;
      const requestBody = {
        id: "mobile_app",
        secret: `${SECRET}`,
      };

      try {
        const response = await fetch(clientUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error:", error);
      }
    }

    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true before making the API call

        const tokenResponse = await authTokenGenerator();
        const token = tokenResponse.data.access_token;

        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Use the retrieved token here
            "Content-Type": "application/json",
          },
        };

        const newUrl = BASEURL_ORDER + "/" + orderId;
        const response = await fetch(newUrl, requestOptions);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false); // Set loading to false when API call is complete
      }
    };

    const loadSecureTradingScript = () => {
      const script = document.createElement("script");
      script.src = "https://cdn.eu.trustpayments.com/js/latest/st.js"; // Replace with the CDN domain for SecureTrading script
      script.onload = initializeSecureTrading;
      document.body.appendChild(script);
    };

    const initializeSecureTrading = async () => {
      if (window.SecureTrading) {
        const fetchOrder = await fetchData();
        const order = fetchOrder.data;

        if (order != null) {
          const extras = order.providerConfiguration.extras;

          const totalAmount = order.totalAmount * 100;

          const payload = {
            payload: {
              accounttypedescription: "ECOM",
              baseamount: totalAmount,
              orderreference: order.id,
              currencyiso3a: `${order.sendingCurrency}`,
              sitereference: `${SITE_REFERENCE}`,
              requesttypedescriptions: ["THREEDQUERY", "AUTH"],
              credentialsonfile: parseInt(extras["credentialsOnFile"]),
              parenttransactionreference: extras["parentTransactionReference"],
            },
            iat: Math.floor(Date.now() / 1000),
            iss: "JWT@diamondcube.com",
          };

          const token = Encode(
            payload,
            TRUST_JS_SECRET
          );

          const st = window.SecureTrading({
            jwt: token,
            fieldsToSubmit: ["securitycode"],
            livestatus: 0,
            translations:{'Security code': 'CVV'}
          });

          st.Components();
        }else{
            setFailure(true)
        }
      } else {
        console.error("SecureTrading library not loaded");
      }
    };

    loadSecureTradingScript();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div id="st-notification-frame"></div>

      <div
        style={{
          border: "1px solid 	#D3D3D3",
          paddingLeft: "15px",
          paddingRight: "15px",
          maxHeight: "50vh",
          borderRadius: "7px",
          fontFamily: "sans-serif",
          color: "#36454F",
        }}
      >
        {loading && !failure ? "" : <p>Please enter the CVV on your card</p>}
        <div
          style={{
            paddingTop: "40px",
          }}
        >
          <form
            id="st-form"
            action={BASEURL_PAYMENT}
            method="POST"
          >
            <div id="st-security-code" class="st-security-code"></div>
            {loading ? (
              <>
                <Lottie
                  animationData={animationData}
                  loop={true}
                  autoplay={true}
                  style={{ width: "250px", height: "150px" }}
                />
              </>
            ) : (
              <>
                <button
                  type="submit"
                  style={{
                    background: "none",
                    margin: "2em",
                    padding: 8,
                    width: "40%",
                    borderRadius: "4px",
                    color: "white",
                    backgroundColor: "black"
                  }}
                ></button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default TrustPageComponent;
