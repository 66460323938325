import {
    ERROR_PAGE,
    SUCCESS_PAGE,
    FAILURE_PAGE,
    REQUEST_PAGE,
} from "../pages";

let PageComponents = [
    { name: "SuccessPage", path: "/borga/successful", element: <SUCCESS_PAGE /> },
    { name: "ErrorPage", path: "/borga/error", element: <ERROR_PAGE />},
    { name: "DeclinePage", path: "/borga/decline", element: <FAILURE_PAGE /> },
    { name: "RequestPage", path: "/borga/request", element: <REQUEST_PAGE /> }
];

export { PageComponents };