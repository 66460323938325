import React from 'react';
import { useEffect, useState, useRef } from "react";
import { handleEmailVerificationCheck } from "../../components/main";
import Lottie from "lottie-react";
import animationData from "../../asset/52647.json";

const BASEURL = process.env.REACT_APP_FAILURE_BASEURL;

const FailurePageComponent = () => {
    const [, setLoading] = useState(true);
    const [, setCode] = useState(1);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        const url = window.location.href;
        const splitUrl = url.split("?");
        const queryParameter = splitUrl[1];

        handleEmailVerificationCheck(queryParameter, setCode, setLoading, BASEURL);
    }, []);

    return (
        <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "12vh",
            width: "100vw",
            height: "100vh",
          }}
        >
          <div>
            <Lottie
              animationData={animationData}
              loop={true}
              autoplay={true}
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div
            style={{
              marginTop: "40px",
              fontSize: "1.8em",
              fontFamily: "Sans-serif",
              fontWeight: "bold",
            }}
          >
            Payment Failed!
          </div>
        </div>
      </div>
    )
}

export default FailurePageComponent