export async function handleEmailVerificationCheck(queryParameter, setCode, setLoading, BASEURL) {
    try {
        setLoading(true); // Set loading to true before making the API call

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        const newUrl = BASEURL + "?" + queryParameter;
        const apiResponse = await fetch(newUrl, requestOptions);

        if (apiResponse.status !== 200) {
            setCode(1);
            return;
        }
        const response = await apiResponse.json();

        response.code === 0 ? setCode(response.code) : setCode(1);

    } catch (error) {
        // Handle errors here
    } finally {
        setLoading(false); // Set loading to false when API call is complete
    }
}